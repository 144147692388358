import React from "react";
import styled from "styled-components";

import { spacers, breakpoints } from "../styles/__variables";

interface RoundImageProps {
  source: string;
  alt?: string;
  portrait?: boolean;
}

const RI = styled.div<RoundImageProps>`
  order: 1;
  width: 100%;
  margin-top: ${`${spacers.mobile}px`};
  margin-bottom: ${`${spacers.mobile}px`};

  img {
    display: block;
    width: 100%;
    border-radius: 500px;
    margin: ${`${spacers.mobile}px`} auto;
    width: 300px;
    height: 300px;
  }

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    margin-top: ${`${spacers.tablet}px`};
    margin-bottom: ${`${spacers.tablet}px`};

    img {
      width: 400px;
      height: 400px;
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    width: ${(props) => (props.portrait ? "100%" : "400px")};
    order: 2;
    position: absolute;
    right: ${(props) => (props.portrait ? "0" : `${spacers.desktop}px`)};
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const RoundImage: React.FC<RoundImageProps> = ({ source, alt, portrait }) => {
  return (
    <RI portrait={portrait}>
      <img src={source} alt={alt} />
    </RI>
  );
};

export default RoundImage;
