import React from "react";
import styled from "styled-components";

import { breakpoints, spacers } from "../styles/__variables";

interface TwoThirdsFlexProps {
  styles: {
    backgroundLeft: string;
    backgroundRight: string;
  };
}

const TwoThirds = styled.div<TwoThirdsFlexProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  background: ${(props) => props.styles.backgroundLeft};

  > div:first-of-type {
    background: ${(props) => props.styles.backgroundLeft};
    background-size: cover;
    flex: 1;
    overflow-y: scroll;
    padding: ${`${spacers.mobile}px`} 0;
  }
  > div:nth-of-type(2) {
    background: ${(props) => props.styles.backgroundRight};
    background-position: center top;
    background-size: cover;
    flex: 1;
    min-height: 300px;
  }

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    > div:first-of-type {
      padding: ${`${spacers.tablet}px`} 0;
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;

    > div {
      height: 100%;
    }
    > div:first-of-type {
      max-width: 66vw;
      width: 66vw;
      padding: ${`${spacers.desktop}px`} 0;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100%;
        height: auto;
      }
    }
    > div:nth-of-type(2) {
      position: fixed;
      width: 34vw;
      right: 0;
    }
  }
`;

// @ larger screens 60 / 40 ratio?

const TwoThirdsFlex: React.FC<TwoThirdsFlexProps> = ({ children, styles }) => {
  return <TwoThirds styles={styles}>{children}</TwoThirds>;
};
export default TwoThirdsFlex;
