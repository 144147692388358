import * as React from "react";
// import Helmet from "react-helmet";
import { useState } from "react";
import Navigation from "../components/navigation/Navigation";
import Header from "../components/navigation/Header";
import PageWrapper from "./PageWrapper";
import TwoThirdsFlex from "./TwoThirdsFlex";

import "normalize.css";
import "../styles/base.css";

// import { heights } from "../styles/__variables";

interface TwoThirdsLayoutProps {
  styles: {
    backgroundLeft: string;
    backgroundRight: string;
    height?: string;
    navColor: string;
    navColorDesktop: string;
  };
}
// import { fonts } from "../styles/variables";
// font-family: ${fonts.monospace};

// lifting state up -> closest common parent

// context -> defined somewhere, accessible from everywhere, not good standard solution, replaces redux

const TwoThirdsLayout: React.FC<TwoThirdsLayoutProps> = ({
  children,
  styles,
}) => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  return (
    <div>
      {/* <Header title={data.site.siteMetadata.title} />
        <LayoutMain>{children}</LayoutMain> */}
      <PageWrapper styles={styles}>
        <Header
          isNavVisible={isNavVisible}
          setIsNavVisible={setIsNavVisible}
          navColor={styles.navColor}
          navColorDesktop={styles.navColorDesktop}
        />
        <Navigation
          isNavVisible={isNavVisible}
          setIsNavVisible={setIsNavVisible}
          navLinkColor={styles.navColor}
        />
        <TwoThirdsFlex styles={styles}>{children}</TwoThirdsFlex>
      </PageWrapper>
    </div>
  );
};

export default TwoThirdsLayout;
