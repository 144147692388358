import React from "react";
import styled from "styled-components";
import ListenItem from "./ListItem";
import { spacers, breakpoints } from "../styles/__variables";

interface ListElement {
  item: string;
  index: number;
}

interface ListProps {
  list: ListElement[];
  color: string;
}

const Liste = styled.ul<ListProps>`
  color: ${(props) => props.color};
  list-style-type: none;
  margin-bottom: ${`${spacers.mobile}px`};

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    margin-bottom: ${`${spacers.tablet}px`};
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    margin-bottom: ${`${spacers.desktop}px`};
  }
`;

const List: React.FC<ListProps> = ({ list, color, noMarginLeft }) => {
  return (
    <Liste color={color}>
      {list.map((listitem) => (
        <ListenItem
          item={listitem.item}
          index={listitem.index}
          noMarginLeft={noMarginLeft}
        />
      ))}
    </Liste>
  );
};

export default List;
