import React from "react";
import styled from "styled-components";

import { colors, breakpoints, spacers } from "../styles/__variables";

interface ListenItemProps {
  item: string;
  index: number;
}

const Item = styled.li`
  &:before {
    content: " ";
    display: block;
    background: white;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    position: absolute;
    left: 0px;
    margin: 8px 0;
  }
  font-family: "Montserrat Light";
  font-size: 16px;
  line-height: 32px;
  margin-left: 30px;

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    font-size: 20px;
    line-height: 40px;
    margin-left: 35px;

    &:before {
      height: 20px;
      width: 20px;
      margin: 10px 0;
    }

    &.noMarginLeft {
      margin-left: 20px;
    }
  }
  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    &.noMarginLeft {
      margin-left: 0px;
    }
  }
  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    font-size: 24px;
    line-height: 48px;
    margin-left: 40px;

    &:before {
      height: 24px;
      width: 24px;
      margin: 11px 0;
    }
  }
`;

const ListenItem: React.FC<ListenItemProps> = ({
  item,
  index,
  noMarginLeft,
}) => {
  return (
    <Item key={index} className={noMarginLeft ? "noMarginLeft" : ""}>
      {item}
    </Item>
  );
};

export default ListenItem;
