import React from "react";
import Helmet from "react-helmet";

import TwoThirdsLayout from "../layouts/TwoThirdsLayout";
import Title from "../components/Title";
import RoundImage from "../components/RoundImage";

import Wrapper from "../layouts/Wrapper";

import { colors } from "../styles/__variables";
import Paragraph from "../components/Paragraph";
import List from "../components/List";
import ListenItem from "../components/ListItem";
import ProjekteListe from "../components/ProjektListe";
import ProjekteListenItem from "../components/ProjekteListenItem";
import { meineErfahrungListe, meinePapiereListe } from "../components/data";

const styles = {
  backgroundLeft: colors.blue.background,
  backgroundRight: "url(/assets/images/background/photocase_295550.jpg)",
  navColor: colors.grey.text,
  navColorDesktop: colors.grey.base,
};

// no innerflex, align innerflex items to center!

const Warum: React.FC = () => {
  return (
    <TwoThirdsLayout styles={styles}>
      <Helmet
        title="Portrait – Rundum HR"
        link={[
          {
            rel: "shortcut icon",
            type: "image/png",
            href: `./assets/favicon/android-chrome-192x192.png`,
          },
        ]}
      />

      <div>
        <Wrapper>
          <Title color={colors.grey.base}>Portrait</Title>
          <Paragraph spaced color={colors.grey.text}>
            <b>Meine Papiere</b>
            <br />
          </Paragraph>
          <Wrapper>
            <List
              color={colors.grey.text}
              list={meinePapiereListe}
              noMarginLeft
            />
          </Wrapper>
          <Paragraph spaced color={colors.grey.text}>
            <b>Meine Erfahrung</b>
            <br />
          </Paragraph>
          <Wrapper>
            <List
              color={colors.grey.text}
              list={meineErfahrungListe}
              noMarginLeft
            />
          </Wrapper>
        </Wrapper>
      </div>
      <div>
        <div>
          <RoundImage
            portrait
            source="/assets/images/andrea_rechsteiner_DHZ6301_a.jpg"
          />
        </div>
      </div>
    </TwoThirdsLayout>
  );
};

export default Warum;
